//vendor
require("@fancyapps/fancybox");

//Polyfills
import 'svgxuse/svgxuse.js';

export default class APP {
	constructor() {

		//Handlers
		$(document).on('click', '.btn--toggle-nav', this.navToggle);
		$(document).on('click', '.accordion__toggle', this.toggleAccordion);
		$(document).on('click', '.nav--main-right .nav_item', this.closeNav);

		this.default();

	}

	default() {

		//Unorphanize
		var textElements = document.querySelectorAll( 'p:not(.excluded)' );
		for ( var i = 0; i < textElements.length; i++ ) {
			var textElement = textElements[ i ];
			textElement.innerHTML = textElement.innerHTML.replace(/\s([^\s<]+)\s*$/,'&nbsp;$1');
		}


		// Add smooth scrolling to all links
		$(".scrollto a").on('click', function(event) {

			if (this.hash !== "") {
				event.preventDefault();
		
				var hash = this.hash;
		
				$('html, body').animate({
				scrollTop: $(hash).offset().top - 200
				}, 1000, function() {
		
				//window.location.hash = hash;
				});
			}
		});

	}

	navToggle(e) {

		e.preventDefault();

		var $_nav = $('#mainnav'),
			nav_is_open = $_nav.hasClass('is-open');

		if (nav_is_open) {
			$_nav.removeClass('is-open');
			$('body').removeClass('nav-is-open');
		} else {
			$_nav.addClass('is-open');
			$('body').addClass('nav-is-open');
		}

	}

	closeNav(e) {
		var $_nav = $('#mainnav');
		$_nav.removeClass('is-open');
	}
	

	toggleAccordion(e) {

		e.preventDefault();

		var $_toggle = $(this),
			$_question = $_toggle.parents('.accordion'),
			question_is_opened = $_question.hasClass('active');

		if(question_is_opened) {
			$_question.removeClass('active');
		} else {
			$_question.addClass('active');
		}

	}

};

var app = new APP();



(function($){

	var yoast = {
		accordion: function(){
			$('.wp-block-yoast-faq-block').find('.schema-faq-question').click(function(){
				//Expand or collapse this panel
				$(this).nextAll('.schema-faq-answer').eq(0).slideToggle('fast', function(){
					if( $(this).hasClass('collapse') ){
						$(this).removeClass('collapse');
					}
					else{
						$(this).addClass('collapse');
					}
				});
			
				//Hide the other panels
				$(".schema-faq-answer").not( $(this).nextAll('.schema-faq-answer').eq(0) ).slideUp('fast');
			});

			$('.wp-block-yoast-faq-block .schema-faq-question').click(function(){
				$('.wp-block-yoast-faq-block .schema-faq-question').not( $(this) ).removeClass('collapse');
				if( $(this).hasClass('collapse') ){
					$(this).removeClass('collapse');
				}
				else{
					$(this).addClass('collapse');
				}
			});
		}
	};

	yoast.accordion();

})(jQuery)